import { css } from 'styled-components'

// Headings
export const Heading = css`
  font-size: 3rem;
`
export const HeadingXS = css`
  font-size: 1.8rem;
`
export const HeadingS = css`
  font-size: 2rem;
`
export const HeadingM = css`
  font-size: 2.2rem;
`
export const HeadingL = css`
  font-size: 3.2rem;
`
export const HeadingXL = css`
  font-size: 3.6rem;
`
export const HeadingXXL = css`
  font-size: 4rem;
`

// Paragraphs
export const Paragraph = css`
  font-size: 1.6rem;
`
export const ParagraphXS = css`
  font-size: 1.1rem;
`
export const ParagraphS = css`
  font-size: 1.2rem;
`
export const ParagraphM = css`
  font-size: 1.4rem;
`
export const ParagraphL = css`
  font-size: 1.6rem;
`
export const ParagraphXL = css`
  font-size: 1.8rem;
`
export const ParagraphXXL = css`
  font-size: 2rem;
`

// Customs
export const Button = css`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1;
`
export const Input = css`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.2;
`
export const InputLabel = css`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.375;
`

