import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { breakpoints } from 'theme/breakpoints'
import HeadingBar from 'components/layouts/HeadingBar'
import Button from 'components/Button'
import router from 'router'

const NoMatchView = () => {
  const navigate = useNavigate()

  return (
    <>
      <HeadingBar title='404' />
      <Wrapper>
        <Container>
          <svg height='90' viewBox='0 0 512 512' width='90' xmlns='http://www.w3.org/2000/svg'>
            <path d='m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm94.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0'></path>
          </svg>
          <h1 className='t-heading t-heading--xxl'>Taka ankieta nie istnieje</h1>
          <ActionsWrapper>
            <Button onClick={() => navigate(router.home)}>Powrót</Button>
          </ActionsWrapper>
        </Container>
      </Wrapper>
    </>
  )
}

export default NoMatchView

const Wrapper = styled.section`
  padding-top: 8rem;
  padding-bottom: 20rem;
  background-color: ${(props) => props.theme.colors.bg};
  text-align: center;

  @media ${breakpoints.max.sm} {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  svg {
    margin-bottom: 4rem;
    fill: ${(props) => props.theme.colors.blue};
  }

  h1 {
    color: ${(props) => props.theme.colors.blue};
    margin-bottom: 4rem;
  }

  p {
    color: ${(props) => props.theme.colors.gray};
    margin-bottom: 0;
  }

  p + p {
    margin-top: 1rem;
  }
`

const ActionsWrapper = styled.div`
  margin-top: ${(props) => props.theme.gaps.xxl};
`
