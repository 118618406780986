import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'

const HeadingBar = ({ title }) => {
  return (
    <Wrapper>
      <Container fluid>
        <h1 className='t-heading t-heading--xl t-heading--bold'>{title}</h1>
      </Container>
    </Wrapper>
  )
}

HeadingBar.propTypes = {
  title: PropTypes.string.isRequired,
}

export default HeadingBar

const Wrapper = styled.section`
  background-color: ${(props) => props.theme.colors.blue};
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  padding: 5rem 2rem;
`
