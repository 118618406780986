export default {
  home: '/',

  ankieta: '/ankieta',
  ankietaKsiegowoscKadry: '/ankieta/obsluga-ksiegowo-kadrowa',

  oswiadczenia: '/oswiadczenia',
  oswiadczeniePep: '/oswiadczenia/oswiadczenie-pep',

  politykaPrywatnosci: '/polityka-prywatnosci',

  dziekujemy: '/dziekujemy',
  dziekujemyOswiadczenie: '/dziekujemy-oswiadzczenia',

  noMatch: '*',
}
