import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { breakpoints } from 'theme/breakpoints'

const Radio = ({ label, field, ...props }) => {
  return (
    <OptionLabel>
      <Input {...field} {...props} />
      <span>{label}</span>
    </OptionLabel>
  )
}

Radio.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
}

export default Radio

const OptionLabel = styled.label`
  position: relative;
  display: block;
  margin-top: 1.5rem;

  & + & {
    margin-top: 1rem;
  }

  @media ${breakpoints.min.md} {
    display: inline-block;
    margin-right: 4rem;

    & + & {
      margin-top: 1.5rem;
    }
  }

  span {
    cursor: pointer;
    display: inline-block;
    margin-left: 2.6rem;
  }

  span::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.2rem;
    display: inline-block;
    margin-right: 1rem;
    width: 1.6rem;
    height: 1.6rem;
    border: 1px solid ${(props) => props.theme.colors.blue};
    background-color: ${(props) => props.theme.colors.inputBg};
    border-radius: 50%;
    padding: 2px;
    background-clip: content-box;
  }
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:hover + span:before {
    background-color: ${(props) => props.theme.colors.blueHover};
  }

  &:checked + span:before {
    background-color: ${(props) => props.theme.colors.blue};
  }

  &:disabled + span {
    cursor: auto;
  }

  &:disabled + span:before {
    box-shadow: none;
    background-color: #b8b8b8;
    border-color: #b8b8b8;
    opacity: 1;
  }
`
