import styled, { css } from 'styled-components'
import { breakpoints } from 'theme/breakpoints'
import * as typo from 'theme/typography'

export const FormControl = styled.div`
  display: block;
  width: 100%;
  flex: 0 1 100%;

  ${(props) =>
    props.required &&
    css`
      label {
        &::after {
          content: '*';
          display: inline-block;
          margin-left: 0.2em;
        }
      }
    `}

  ${(props) =>
    props.$valid &&
    css`
      background-position: right 0.6rem top 50%;
      background-size: 1.6rem;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg version='1.2' xmlns='http://www.w3.org/2000/svg' width='17' height='17'%3E%3Cdefs%3E%3Cimage width='17' height='17' id='a' href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAMAAAAMs7fIAAAAAXNSR0IB2cksfwAAADxQTFRFIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UDFMsGgAAABR0Uk5TL9T/hl94ACvH4STkDBXTpQNkqEsqXqBmAAAAS0lEQVR4nGNgYGRCBowMaAIgISZ0QKoIMwuaCCsbO1yEgxMswMUNF+Hh5YMLQET4BXgFYQJQc/gF4AIwk4WEYQIkuxAqgul3jPABAOUnA0TBMxabAAAAAElFTkSuQmCC'/%3E%3C/defs%3E%3Cuse href='%23a' opacity='.2'/%3E%3C/svg%3E");
    `}


  ${(props) =>
    props.half &&
    css`
      flex: 0 1 calc((100% - ${(props) => props.theme.gaps.m}) / 2);
    `}


  ${(props) =>
    props.blank &&
    css`
      margin: 0;
    `}
  
    &--required {
    .c-input {
    }
  }
`

export const Error = styled.span.attrs({
  'data-error': true,
})`
  ${typo.ParagraphXS}
  display: block;
  color: ${(props) => props.theme.colors.red};
`

export const Label = styled.p`
  ${typo.InputLabel}
  display: inline-block;
`
