import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as CloseBtn } from 'assets/svgs/close-button.svg'
import Portal from './Portal'
import PropTypes from 'prop-types'

const Modal = ({ title, show, onClose, children }) => {
  useEffect(() => {
    document.body.style.overflow = show ? 'hidden' : 'unset'
  }, [show])

  return (
    <Portal>
      {show && (
        <ModalWrapper>
          <Background onClick={onClose} />
          <ModalContentWrapper>
            <Title>{title}</Title>
            <StyledCloseBtn onClick={onClose}>Zamknij</StyledCloseBtn>
            {children}
          </ModalContentWrapper>
        </ModalWrapper>
      )}
    </Portal>
  )
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}
export default Modal

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
  overflow: auto;
`

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  backdrop-filter: blur(1px);
  background-color: ${(props) => props.theme.colors.bgBlack};
`

const ModalContentWrapper = styled.div`
  position: relative;
  z-index: 1001;

  width: 100%;
  max-width: 70rem;
  height: auto;
  min-height: 25rem;
  max-height: 80vh;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 3px 15px #00000050;
  border-radius: 15px;
  border: 2px solid ${(props) => props.theme.colors.blue};

  p {
    margin-bottom: 1em;
  }
`

const StyledCloseBtn = styled(CloseBtn)`
  fill: ${(props) => props.theme.colors.orange};
  width: 2.2rem;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;

  &:hover {
    fill: ${(props) => props.theme.colors.blue};
  }
`

const Title = styled.h2`
  margin-bottom: 1em;
`
