import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

const Loading = ({ show = false, loadingText = 'Ładowanie', isFixed = true }) => {
  return (
    show && (
      <Wrapper isFixed={isFixed}>
        <Loader>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </Loader>
        {loadingText && <h1 className='t-heading t-heading--s u-text-center'>{loadingText}</h1>}
      </Wrapper>
    )
  )
}

Loading.propTypes = {
  show: PropTypes.bool,
  isFixed: PropTypes.bool,
  loadingText: PropTypes.string,
}
export default Loading

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.bgBlack};
  color: ${(props) => props.theme.colors.blue};

  ${(props) =>
    props.isFixed &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2000;
    `}
`

const Loader = styled.div`
  display: block;
  position: relative;
  width: 8rem;
  height: 8rem;

  div {
    position: absolute;
    top: 33px;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.blue};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`
