import styled from 'styled-components'
import { Button as ButtonTypo } from 'theme/typography'

export default styled.button`
  ${ButtonTypo}
  cursor: pointer;
  line-height: 1;
  font-weight: 600;
  padding: 1.6rem 3rem;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.blue};
  border: 2px solid ${(props) => props.theme.colors.blue};
  border-radius: ${(props) => props.theme.borderRadius};
  transition:
    color 0.3s,
    background-color 0.3s;

  &:hover,
  &:active {
    color: ${(props) => props.theme.colors.blue};
    background-color: ${(props) => props.theme.colors.white};
  }
`
