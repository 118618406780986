import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { Field, Formik, Form } from 'formik'
import { Col, Container, Row } from 'react-bootstrap'
import { ankietaKsiegowoscKadrySchema } from 'utils/validations'
import * as options from 'utils/config'
import router from 'router'
import { Label } from 'components/Forms/elements'
import { breakpoints } from 'theme/breakpoints'
import { ScrollToError } from 'components/ScrollToError'
import Heading from 'components/Heading'
import HeadingBar from 'components/layouts/HeadingBar'
import TextInput from 'components/Forms/Inputs/TextInput'
import OtherAnswerBox from 'components/Forms/elements/OtherAnswerBox'
import Radio from 'components/Forms/Inputs/Radio'
import Checkbox from 'components/Forms/Inputs/Checkbox'
import Switch from 'components/Forms/Inputs/Switch'
import Button from 'components/Button'
import InputError from 'components/Forms/elements/Error'
import Modal from 'components/Modal'
import Loading from 'components/Loading'

const AnkietaKsiegowoscKadryView = () => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = (values) => {
    setLoading(true)

    axios
      .post(options.endpoints.ankietaKsiekowośćKardy, {
        ...values,
      })
      .then((res) => {
        console.log(res)
        navigate(router.dziekujemy)
      })
      .catch((err) => {
        console.log(err)
        setError('Coś poszło nie tak, spróbuj ponownie za chwilę.')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <Loading show={loading} />
      <Modal title='Błąd' show={error} onClose={() => setError(false)}>
        {error}
      </Modal>
      <HeadingBar title='Ankieta dot. obsługi księgowo – kadrowej' />
      <Wrapper>
        <Container>
          <Row>
            <Col md={{ span: 11, offset: 1 }}>
              <Formik
                initialValues={{
                  // Section 1
                  isNewCompany: '',
                  businessType: '',
                  entityName: '',
                  nip: '',
                  pesel: '',
                  phone: '',
                  www: '',
                  email: '',
                  // Section 2
                  zipCode: '',
                  street: '',
                  city: '',
                  buildingNumber: '',

                  // Section 4
                  businessModel: '',
                  whoDoesAccounting: '',
                  whoDoesAccountingOther: '',
                  areYouSatisfied: '',
                  areYouSatisfiedOther: '',
                  whatIsMissingFromService: '',
                  modernServiceYouCareAbout: [],
                  whyYouWantToChangeService: '',
                  formOfSettlement: '',
                  tax: '',
                  foreignIncome: false,
                  isActiveVatPayer: false,
                  vat: '',
                  vatEU: false,
                  numberOfRevenueInvoicesMonthly: '',
                  numberOfCostInvoicesMonthly: '',
                  numberOfForeingRevenueInvoicesMonthly: '',
                  numberOfForeingCostInvoicesMonthly: '',
                  doYouEmployWorkers: false,
                  numberOfEmployeesContract: '',
                  numberOfEmployeesMandate: '',
                  numberOfNonresidentEmployees: '',
                  usedProgram: '',
                  programActions: [],
                  programActionsOther: '',
                  whatSoftwareAccountantWorkOn: '',
                  // Section 5
                  costAccountant: '',
                  costHumanResources: '',
                  // Section 6
                  howYouLearnAboutUs: [],
                  howYouLearnAboutUsOther: '',
                  howYouLearnAboutUsName: '',
                  howYouLearnAboutUsPhone: '',
                  othersInformations: '',
                  regulations: false,
                }}
                validationSchema={ankietaKsiegowoscKadrySchema}
                onSubmit={handleSubmit}
              >
                {({ values, errors }) => (
                  <>
                    <ScrollToError />
                    <StyledFormikForm>
                      {/* Sekcja 1 */}

                      <Section>
                        <StyledHeading>Informacje podstawowe podmiotu</StyledHeading>
                        <Row className='u-mb-l'>
                          <Label>Czy jest to nowa firma?</Label>
                          <Col>
                            {options.isNewCompanyOptions.map((opt) => (
                              <Field
                                key={opt.value}
                                disabled={false}
                                name='isNewCompany'
                                type='radio'
                                value={opt.value}
                                label={opt.display}
                                component={Radio}
                              />
                            ))}
                            <InputError name='isNewCompany' />
                          </Col>
                        </Row>
                        <Row className='u-mb-l'>
                          <Label>Rodzaj działalności gospodarczej:</Label>
                          <Col>
                            {options.businessTypeOptions.map((opt) => (
                              <Field
                                key={opt.value}
                                disabled={false}
                                name='businessType'
                                type='radio'
                                value={opt.value}
                                label={opt.display}
                                component={Radio}
                              />
                            ))}
                            <InputError name='businessType' />
                          </Col>
                        </Row>
                        <Row className='u-mb gaps'>
                          <Col md='4'>
                            <Field
                              disabled={false}
                              label={
                                ['planuje'].includes(values.isNewCompany)
                                  ? 'Nazwa podmiotu / Imię i nazwisko'
                                  : 'Nazwa podmiotu:'
                              }
                              name='entityName'
                              placeholder='Jan Kowalski'
                              component={TextInput}
                            />
                          </Col>
                          <Col md='4'>
                            <Field disabled={false} label='NIP:' name='nip' component={TextInput} />
                          </Col>
                          {['jednoosobowa-dzialalnosc-gospodarcza', 'spolka-cywilna', 'spolka-jawna'].includes(
                            values.businessType,
                          ) && (
                            <Col md='4'>
                              <Field
                                disabled={false}
                                label='PESEL:'
                                name='pesel'
                                hint='(w przypadku działalności gospodarczej)'
                                component={TextInput}
                              />
                            </Col>
                          )}
                          <Col md='4'>
                            <Field disabled={false} label='Numer telefonu:' name='phone' component={TextInput} />
                          </Col>
                          <Col md='4'>
                            <Field disabled={false} label='Strona internetowa:' name='www' component={TextInput} />
                          </Col>
                          <Col md='4'>
                            <Field disabled={false} label='E-mail:' name='email' component={TextInput} />
                          </Col>
                        </Row>
                      </Section>

                      {/* Sekcja 2 */}
                      <Section $marginTop>
                        <StyledHeading>Adres podmiotu</StyledHeading>
                        <Row className='u-mb-s gaps'>
                          <Col md='6' lg='3'>
                            <Field
                              disabled={false}
                              label='Kod pocztowy:'
                              name='zipCode'
                              placeholder='＿ ＿ - ＿ ＿ ＿'
                              component={TextInput}
                            />
                          </Col>
                          <Col md='6' lg='3'>
                            <Field disabled={false} label='Miejscowość:' name='city' component={TextInput} />
                          </Col>
                          <Col md='6' lg='3'>
                            <Field disabled={false} label='Ulica:' name='street' component={TextInput} />
                          </Col>
                          <Col md='6' lg='3'>
                            <Field
                              disabled={false}
                              label='Numer domu/lokalu:'
                              name='buildingNumber'
                              component={TextInput}
                            />
                          </Col>
                        </Row>
                      </Section>

                      {/* Sekcja 3 */}
                      <Section $marginTop>
                        <StyledHeading>Informacje</StyledHeading>

                        <Row className='u-mb-l'>
                          <Col>
                            <Field
                              disabled={false}
                              label={
                                ['planuje'].includes(values.isNewCompany)
                                  ? 'Model biznesowy podmiotu (czym będzie zajmował się podmiot - branża):'
                                  : 'Model biznesowy podmiotu (branża):'
                              }
                              name='businessModel'
                              component={TextInput}
                            />
                          </Col>
                        </Row>
                        {['nie', ''].includes(values.isNewCompany) && (
                          <>
                            <Row className='u-mb-l'>
                              <Label>Kto prowadzi księgowość?</Label>
                              <Col>
                                {options.whoDoesAccountingOptions.map((opt) => (
                                  <Field
                                    key={opt.value}
                                    disabled={false}
                                    name='whoDoesAccounting'
                                    type='radio'
                                    value={opt.value}
                                    label={opt.display}
                                    component={Radio}
                                  />
                                ))}
                                <InputError name='whoDoesAccounting' />
                                {['inne'].includes(values.whoDoesAccounting) && (
                                  <OtherAnswerBox title='Kto?'>
                                    <Field
                                      disabled={false}
                                      placeholder='Wpisz odpowiedź'
                                      name='whoDoesAccountingOther'
                                      component={TextInput}
                                    />
                                  </OtherAnswerBox>
                                )}
                              </Col>
                            </Row>
                            <Row className='u-mb-l'>
                              <Label>Czy są Państwo zadowoleni z usługi?</Label>
                              <Col>
                                {options.areYouSatisfiedOptions.map((opt) => (
                                  <Field
                                    key={opt.value}
                                    disabled={false}
                                    name='areYouSatisfied'
                                    type='radio'
                                    value={opt.value}
                                    label={opt.display}
                                    component={Radio}
                                  />
                                ))}
                                <InputError name='areYouSatisfied' />
                                {['nie', 'inne'].includes(values.areYouSatisfied) && (
                                  <OtherAnswerBox title={values.areYouSatisfied === 'nie' ? 'Dlaczego?' : 'Jaki?'}>
                                    <Field
                                      disabled={false}
                                      placeholder='Wpisz odpowiedź'
                                      name='areYouSatisfiedOther'
                                      component={TextInput}
                                    />
                                  </OtherAnswerBox>
                                )}
                              </Col>
                            </Row>
                            <Row className='u-mb-l'>
                              <Col>
                                <Field
                                  disabled={false}
                                  label='Czego brakuje Państwu w obsłudze?'
                                  name='whatIsMissingFromService'
                                  component={TextInput}
                                />
                              </Col>
                            </Row>
                          </>
                        )}

                        <Row className='u-mb-l'>
                          <Label>Czy są jakieś nowoczesne elementy obsługi, na których Państwu zależy?</Label>
                          <Col>
                            {options.modernServiceYouCareAboutOptions.map((opt) => (
                              <Field
                                key={opt.value}
                                disabled={false}
                                name='modernServiceYouCareAbout'
                                type='checkbox'
                                value={opt.value}
                                label={opt.display}
                                component={Checkbox}
                              />
                            ))}
                            <InputError name='modernServiceYouCareAbout' />
                            {values.modernServiceYouCareAbout.includes('inne') && (
                              <OtherAnswerBox title='Jakie?'>
                                <Field
                                  disabled={false}
                                  placeholder='Wpisz odpowiedź'
                                  name='modernServiceYouCareAboutOther'
                                  component={TextInput}
                                />
                              </OtherAnswerBox>
                            )}
                          </Col>
                        </Row>
                        {['nie', ''].includes(values.isNewCompany) && (
                          <Row className='u-mb-l'>
                            <Col>
                              <Field
                                disabled={false}
                                label='Dlaczego chcieliby Państwo zmienić obsługę?'
                                name='whyYouWantToChangeService'
                                component={TextInput}
                              />
                            </Col>
                          </Row>
                        )}
                        <Row className='u-mb-l'>
                          <Label>Forma rozliczenia:</Label>
                          <Col>
                            {options.formOfSettlementOptions.map((opt) => (
                              <Field
                                key={opt.value}
                                disabled={false}
                                name='formOfSettlement'
                                type='radio'
                                value={opt.value}
                                label={opt.display}
                                component={Radio}
                              />
                            ))}
                            <InputError name='formOfSettlement' />
                          </Col>
                        </Row>
                        {!['planuje'].includes(values.isNewCompany) && (
                          <Row className='u-mb-l'>
                            <Label>Podatek:</Label>
                            <Col>
                              {options.taxOptions.map((opt) => (
                                <Field
                                  key={opt.value}
                                  disabled={false}
                                  name='tax'
                                  type='radio'
                                  value={opt.value}
                                  label={opt.display}
                                  component={Radio}
                                />
                              ))}
                              <InputError name='tax' />
                            </Col>
                          </Row>
                        )}

                        <Row className='u-mb-l'>
                          <Label>Dochody z zagranicy:</Label>
                          <Col>
                            <Field disabled={false} name='foreignIncome' type='checkbox' component={Switch} />
                          </Col>
                        </Row>
                        <Row className='u-mb-l'>
                          {['planuje'].includes(values.isNewCompany) ? (
                            <Label>Czy podmiot będzie czynnym podatnikiem VAT?</Label>
                          ) : (
                            <Label>Czy podmiot jest czynnym podatnikiem VAT?</Label>
                          )}
                          <Col>
                            <Field disabled={false} name='isActiveVatPayer' type='checkbox' component={Switch} />
                          </Col>
                        </Row>
                        {!['planuje'].includes(values.isNewCompany) && values.isActiveVatPayer && (
                          <>
                            <Row className='u-mb-l'>
                              <Label>Podatek VAT:</Label>
                              <Col>
                                {options.vatOptions.map((opt) => (
                                  <Field
                                    key={opt.value}
                                    disabled={false}
                                    name='vat'
                                    type='radio'
                                    value={opt.value}
                                    label={opt.display}
                                    component={Radio}
                                  />
                                ))}
                                <InputError name='vat' />
                              </Col>
                            </Row>
                            <Row className='u-mb-l'>
                              <Label>VAT UE:</Label>
                              <Col>
                                <Field disabled={false} name='vatEU' type='checkbox' component={Switch} />
                              </Col>
                            </Row>
                          </>
                        )}
                        <Row className='u-mb-l gaps'>
                          <Col md='6'>
                            <Field
                              disabled={false}
                              label='Ilość faktur przychodowych (miesięcznie):'
                              name='numberOfRevenueInvoicesMonthly'
                              type='number'
                              component={TextInput}
                            />
                          </Col>
                          <Col md='6'>
                            <Field
                              disabled={false}
                              label='Ilość faktur kosztowych (miesięcznie):'
                              name='numberOfCostInvoicesMonthly'
                              type='number'
                              component={TextInput}
                            />
                          </Col>
                          <Col md='6'>
                            <Field
                              disabled={false}
                              label='Ilość faktur przychodowych zagranicznych (miesięcznie):'
                              name='numberOfForeingRevenueInvoicesMonthly'
                              type='number'
                              component={TextInput}
                            />
                          </Col>
                          <Col md='6'>
                            <Field
                              disabled={false}
                              label='Ilość faktur kosztowych zagranicznych (miesięcznie):'
                              name='numberOfForeingCostInvoicesMonthly'
                              type='number'
                              component={TextInput}
                            />
                          </Col>
                        </Row>
                        <Row className='u-mb-l'>
                          {['planuje'].includes(values.isNewCompany) ? (
                            <Label>Czy będą Państwo zatrudniać pracowników?</Label>
                          ) : (
                            <Label>Czy zatrudniają Państwo pracowników?</Label>
                          )}
                          <Col>
                            <Field disabled={false} name='doYouEmployWorkers' type='checkbox' component={Switch} />
                          </Col>
                        </Row>
                        {!['planuje'].includes(values.isNewCompany) && values.doYouEmployWorkers && (
                          <Row className='u-mb-l gaps'>
                            <Col md='4'>
                              <Field
                                disabled={false}
                                label='Ilość pracowników na umowę o pracę:'
                                name='numberOfEmployeesContract'
                                type='number'
                                component={TextInput}
                              />
                            </Col>
                            <Col md='4'>
                              <Field
                                disabled={false}
                                label='Ilość pracowników na umowę zlecenie:'
                                name='numberOfEmployeesMandate'
                                type='number'
                                component={TextInput}
                              />
                            </Col>
                            <Col md='4'>
                              <Field
                                disabled={false}
                                label='Ilość pracowników nierezydentów:'
                                name='numberOfNonresidentEmployees'
                                type='number'
                                component={TextInput}
                              />
                            </Col>
                          </Row>
                        )}
                        {!['planuje'].includes(values.isNewCompany) && (
                          <Row className='u-mb-l'>
                            <Col>
                              <Field
                                disabled={false}
                                label='Na jakim programie Państwo pracują?'
                                name='usedProgram'
                                component={TextInput}
                              />
                            </Col>
                          </Row>
                        )}
                        {!['planuje'].includes(values.isNewCompany) && (
                          <Row className='u-mb-l'>
                            <Label>Jakie działania wykonują Państwo na tym programie?</Label>
                            <Col>
                              {options.programActions.map((opt) => (
                                <Field
                                  key={opt.value}
                                  disabled={false}
                                  name='programActions'
                                  type='checkbox'
                                  value={opt.value}
                                  label={opt.display}
                                  component={Checkbox}
                                  block
                                />
                              ))}
                              <InputError name='programActions' />
                              {values.programActions.includes('inne') && (
                                <OtherAnswerBox title='Jakie?'>
                                  <Field
                                    disabled={false}
                                    placeholder='Wpisz odpowiedź'
                                    name='programActionsOther'
                                    component={TextInput}
                                  />
                                </OtherAnswerBox>
                              )}
                            </Col>
                          </Row>
                        )}

                        {['nie'].includes(values.isNewCompany) && (
                          <>
                            <Row className='u-mb-l'>
                              <Col>
                                <Field
                                  disabled={false}
                                  label='Na jakim programie pracuje księgowa?'
                                  name='whatSoftwareAccountantWorkOn'
                                  component={TextInput}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      </Section>

                      {/* Sekcja 4 */}
                      {['nie'].includes(values.isNewCompany) && (
                        <Section $marginTop>
                          <StyledHeading>Ile aktualnie płacą Państwo za:</StyledHeading>
                          <Row className='u-mb-l gaps'>
                            <Col md='6'>
                              <Field
                                disabled={false}
                                label='Księgowość:'
                                name='costAccountant'
                                component={TextInput}
                                type='number'
                              />
                            </Col>
                            <Col md='6'>
                              <Field
                                disabled={false}
                                label='Kadry:'
                                name='costHumanResources'
                                component={TextInput}
                                type='number'
                              />
                            </Col>
                          </Row>
                        </Section>
                      )}
                      {/* Sekcja 5 */}
                      <Section $marginTop>
                        <StyledHeading>Podsumowanie</StyledHeading>
                        <Row className='u-mb-l'>
                          <Label>Skąd dowiedzieli się Państwo o naszej firmie?</Label>
                          <Col>
                            {options.howYouLearnAboutUsOptions.map((opt) => (
                              <Field
                                key={opt.value}
                                disabled={false}
                                name='howYouLearnAboutUs'
                                type='checkbox'
                                value={opt.value}
                                label={opt.display}
                                component={Checkbox}
                              />
                            ))}
                            <InputError name='howYouLearnAboutUs' />
                            {values.howYouLearnAboutUs.includes('inne') && (
                              <OtherAnswerBox title='Skąd?'>
                                <Field
                                  disabled={false}
                                  placeholder='Wpisz odpowiedź'
                                  name='howYouLearnAboutUsOther'
                                  component={TextInput}
                                />
                              </OtherAnswerBox>
                            )}
                          </Col>
                        </Row>
                        {values.howYouLearnAboutUs.includes('z-polecenia') && (
                          <Row className='u-mb-l gaps'>
                            <Label>Prosimy o podanie danych osoby/ firmy, od której otrzymali Państwo polecenie</Label>
                            <Col md='6'>
                              <Field
                                disabled={false}
                                label='Imię i nazwisko lub nazwa firmy'
                                name='howYouLearnAboutUsName'
                                component={TextInput}
                                type='text'
                              />
                            </Col>
                            <Col md='6'>
                              <Field
                                disabled={false}
                                label='Numer telefonu i/lub adres e-mail'
                                name='howYouLearnAboutUsPhone'
                                component={TextInput}
                                type='text'
                              />
                            </Col>
                          </Row>
                        )}
                        <Row className='u-mb-l'>
                          <Col>
                            <Field
                              disabled={false}
                              label='Informacje dodatkowe'
                              name='othersInformations'
                              component={TextInput}
                            />
                          </Col>
                        </Row>
                        <Row className='u-mb-l'>
                          <Col>
                            <Field
                              disabled={false}
                              name='regulations'
                              type='checkbox'
                              label={
                                <>
                                  <span>Oświadczam, że zapoznałem/-am się z </span>{' '}
                                  <Link className='link' target='_blank' to={router.politykaPrywatnosci}>
                                    Polityką prywatności
                                  </Link>
                                  <span>.</span>
                                </>
                              }
                              component={Checkbox}
                            />
                            <InputError name='regulations' />
                          </Col>
                        </Row>
                      </Section>
                      <ActionsWrapper>
                        <Button type='submit'>Wyślij ankietę</Button>
                      </ActionsWrapper>
                    </StyledFormikForm>
                  </>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </>
  )
}

export default AnkietaKsiegowoscKadryView

const Wrapper = styled.section`
  padding-top: 6rem;
  padding-bottom: 8rem;
  background-color: ${(props) => props.theme.colors.bg};

  @media ${breakpoints.max.sm} {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`

const StyledFormikForm = styled(Form)``
const StyledHeading = styled(Heading)``

const Section = styled.section`
  position: relative;

  ${(props) =>
    props.$marginTop &&
    css`
      margin-top: ${(props) => props.theme.gaps.xl};
    `};

  .gaps {
    row-gap: 1.5rem;
  }

  @media ${breakpoints.min.md} {
    &:not(:last-of-type):before {
      content: '';
      display: block;
      position: absolute;
      top: 3rem;
      bottom: -4rem;
      left: -10rem;
      width: 1px;
      background-color: ${(props) => props.theme.colors.border};
    }

    ${StyledHeading} {
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0rem;
        left: -10rem;
        width: 2rem;
        height: 2rem;
        border: 2px solid ${(props) => props.theme.colors.border};
        padding: 2px;
        background-clip: content-box;
        border-radius: 50%;
        transform: translateX(-50%);
        background-color: ${(props) => props.theme.colors.blue};
      }
    }
  }
`

const ActionsWrapper = styled.div`
  margin-top: ${(props) => props.theme.gaps.xxl};
`
