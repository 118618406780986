import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { breakpoints } from 'theme/breakpoints'

const Checkbox = ({ label, field, block = false, ...props }) => {
  return (
    <OptionLabel $block={block}>
      <Input {...field} {...props} />
      <span>{label}</span>
    </OptionLabel>
  )
}

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  field: PropTypes.object.isRequired,
  block: PropTypes.bool,
}

export default Checkbox

const OptionLabel = styled.label`
  position: relative;
  display: block;
  margin-top: 1.5rem;

  & + & {
    margin-top: 1rem;
  }

  @media ${breakpoints.min.md} {
    display: inline-block;
    margin-right: 4rem;

    & + & {
      margin-top: 1.5rem;
    }
  }

  ${(props) =>
    props.$block &&
    css`
      display: block;

      & + & {
        margin-top: 1rem;
      }
    `};

  & > span {
    cursor: pointer;
    display: inline-block;
    margin-left: 2.6rem;

    &::before {
      content: '';
      position: absolute;
      top: 0.2rem;
      left: 0;
      display: inline-block;
      width: 1.6rem;
      height: 1.6rem;
      border: 1px solid ${(props) => props.theme.colors.blue};
      background-color: ${(props) => props.theme.colors.inputBg};
      padding: 2px;
      background-clip: content-box;
    }
  }
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:hover + span:before {
    background-color: ${(props) => props.theme.colors.blueHover};
  }

  &:checked + span:before {
    background-color: ${(props) => props.theme.colors.blue};
  }

  &:disabled + span {
    cursor: auto;
  }

  &:disabled + span:before {
    box-shadow: none;
    background-color: #b8b8b8;
    border-color: #b8b8b8;
    opacity: 1;
  }
`
