import { createGlobalStyle } from 'styled-components'
import * as typo from 'theme/typography'
import { breakpoints } from './breakpoints'
import 'theme/scss/bootstrap.scss'

export default createGlobalStyle`
  *,
  *::before,
  *::after {
      margin: 0;
      padding: 0;
      box-sizing: inherit;
  }


  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    scrollbar-gutter: stable;
    font-size: 50%;

    @media ${breakpoints.min.lg} {
      font-size: 56.25%;
    }

    @media ${breakpoints.min.xl} {
      font-size: 62.5%;
    }
  }

  body {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    color: ${(props) => props.theme.colors.black};
    font-size: 1.6rem;
    line-height: 1.2;
    overflow-x: hidden;
    text-rendering: optimizeLegibility;
  }

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    background: none;
    border: none;
    outline: none;
    color: inherit;
  }

  input,
  select,
  textarea,
  button {
    font-family: "Open Sans", sans-serif;

    &:focus {
      outline: 1px solid ${(props) => props.theme.colors.blue};
    }
  }

  /* *::-webkit-scrollbar, textarea::-webkit-scrollbar, iframe::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  *::-webkit-scrollbar-track, textarea::-webkit-scrollbar-track, iframe::-webkit-scrollbar {
    background-color: #D8DFE8;
    border-radius: 4px;
  }

  *::-webkit-scrollbar-thumb, textarea::-webkit-scrollbar-thumb, iframe::-webkit-scrollbar {
    background-color: #F18819;
    border-radius: 4px;
  } */


  .t-paragraph,
  .t-heading {
      margin-bottom: ${(props) => props.theme.gaps.default};

      &:last-child {
          margin-bottom: 0;
      }
  }

  .t-heading {
      ${typo.Heading}
      font-weight: 700;

      &--xs {
        ${typo.HeadingXS}        
      }
      &--s {
        ${typo.HeadingS}        
      }
      &--m {
        ${typo.HeadingM}        
      }
      &--l {
        ${typo.HeadingL}        
      }
      &--xl {
        ${typo.HeadingXL}        
      }
      &--xxl {
        ${typo.HeadingXXL}        
      }
  }

  .t-paragraph {
      ${typo.Paragraph}

      &--xs {
          ${typo.ParagraphXS} 
      }
      &--s {
          ${typo.ParagraphS} 
      }
      &--m {
          ${typo.ParagraphM} 
      }
      &--l {
          ${typo.ParagraphL} 
      }
      &--xl {
          ${typo.ParagraphXL} 
      }
      &--xxl {
          ${typo.ParagraphXXL} 
      }
  }

  .t-paragraph,
  .t-heading {
      &--italic {
          font-style: italic;
      }

      &--regular {
          font-weight: 400;
      }

      &--medium {
          font-weight: 500;
      }

      &--semi {
          font-weight: 600;
      }

      &--bold {
          font-weight: 700;
      }
  }

  .link {
      cursor: pointer;
      color: ${(props) => props.theme.colors.red};
      font-weight: 600;

      &:link,
      &:visited {
        color: ${(props) => props.theme.colors.blue};
      }

      &:hover,
      &:active {
        text-decoration: underline;
      }
  }

  .t-content {
    line-height: 1.45;

    h1,h2,h3,h4,h5 {
      font-weight: 700;
    }

    & * {
      margin-bottom: 1em;
    }

    a {
      color: ${(props) => props.theme.colors.red};
      font-weight: 600;
      cursor: pointer;

      &:link,
      &:visited {
        color: ${(props) => props.theme.colors.blue};
      }

      &:hover,
      &:active {
        text-decoration: underline;
      }
    }

    ul,ol {
      margin-top: 1.25em;
      margin-bottom: 1.5em;
      padding-left: 2.2em;

      li {
        margin-bottom: .5em;
      }
    }
  }

  // Utilities
  .u-color-red {
    color: ${(props) => props.theme.colors.red};
  }

  .u-color-blue {
    color: ${(props) => props.theme.colors.blue};
  }

  .u-text-center {
    text-align: center;
  }
  
  .u-text-right {
    text-align: right;
  }

  .u-mb-s {
    margin-bottom: ${(props) => props.theme.gaps.s};
  }

  .u-mb-m {
    margin-bottom: ${(props) => props.theme.gaps.m};
  }

  .u-mb {
    margin-bottom: ${(props) => props.theme.gaps.default};
  }

  .u-mb-l {
    margin-bottom: ${(props) => props.theme.gaps.l};
  }

  .u-mb-xl {
    margin-bottom: ${(props) => props.theme.gaps.xl};
  }
  
  .u-mb-xxl {
    margin-bottom: ${(props) => props.theme.gaps.xxl};
  }

  .u-w-100 {
    width: 100%;
  }

  .u-lh-l {
    line-height: 1.6;
  }

  .u-lh-xl {
    line-height: 1.8;
  }

  .u-lh-xxl {
    line-height: 2;
  }

  .u-lh-xxxl {
    line-height: 2.2;
  }
`
