import { css } from 'styled-components'

export const centerBlock = css`
  margin-left: auto;
  margin-right: auto;
`

export const placeholder = (innjectedCSS) => css`
  &::-webkit-input-placeholder {
    ${innjectedCSS}
  }
  &::-moz-placeholder {
    ${innjectedCSS}
  }
  &:-ms-input-placeholder {
    ${innjectedCSS}
  }
  &:-moz-placeholder {
    ${innjectedCSS}
  }
`
