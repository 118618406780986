import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { Error } from 'components/Forms/elements'

const InputError = ({ name }) => {
  const { touched, errors } = useFormikContext()
  return <>{touched[name] && errors[name] && <CustomError>{errors[name]}</CustomError>}</>
}

InputError.propTypes = {
  name: PropTypes.string.isRequired,
}

export default InputError

const CustomError = styled(Error)`
  margin-top: 1rem;
  margin-left: 4px;
`
