import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Switch = ({ field, ...props }) => {
  return (
    <OptionLabel>
      <Input {...field} {...props} />
      <span />
    </OptionLabel>
  )
}

Switch.propTypes = {
  field: PropTypes.object.isRequired,
}

export default Switch

const OptionLabel = styled.label`
  display: inline-block;
  margin-top: 1.5rem;

  span {
    display: inline-block;
    position: relative;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.border};
    border-radius: 3.6rem;
    transition: background-color 0.3s;
    width: 3.6rem;
    height: 2rem;
  }

  span::before {
    position: absolute;
    content: '';
    height: 1.4rem;
    width: 1.4rem;
    left: 0.3rem;
    bottom: 0.3rem;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 50%;
    transition: transform 0.3s;
  }
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:hover + span {
    background-color: ${(props) => props.theme.colors.blueHover};
  }

  &:checked + span {
    background-color: ${(props) => props.theme.colors.blue};
  }

  &:checked + span::before {
    transform: translateX(1.6rem);
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 1;
  }
`
