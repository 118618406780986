import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as typo from 'theme/typography'
import TextInput from '../Inputs/TextInput'

const OtherAnswerBox = ({ title, children }) => (
  <StyledFieldset>
    <legend>{title}</legend>
    {children}
  </StyledFieldset>
)

OtherAnswerBox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default OtherAnswerBox

const StyledFieldset = styled.fieldset`
  padding: 0.5rem 2.5rem 2rem 2.5rem;
  margin-top: 2rem;
  border-radius: ${(props) => props.theme.border};
  border: 1px solid ${(props) => props.theme.colors.border};

  legend {
    ${typo.ParagraphM};
    color: ${(props) => props.theme.colors.blue};
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: -1rem;
  }

  input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.colors.dark};
    border-radius: 0;
  }
`
