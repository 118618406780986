import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { HeadingXS } from 'theme/typography'

const Heading = ({ marginTop = false, children, className }) => {
  return (
    <StyledHeading $marginTop={marginTop} className={className}>
      {children}
    </StyledHeading>
  )
}

Heading.propTypes = {
  marginTop: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Heading

const StyledHeading = styled.h2`
  ${HeadingXS}
  font-weight: 700;
  color: ${(props) => props.theme.colors.blue};
  margin-bottom: ${(props) => props.theme.gaps.default};

  ${(props) =>
    props.$marginTop &&
    css`
      margin-top: ${(props) => props.theme.gaps.xl};
    `};
`
