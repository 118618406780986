const Sizes = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

const deviceMin = {};
const deviceMax = {};

Object.entries(Sizes).forEach(([key, value]) => {
  deviceMin[key] = `(min-width: ${value}px)`;
  deviceMax[key] = `(max-width: ${Number(value - 0.02).toFixed(2)}px)`;
});

export const breakpoints = {
  min: { ...deviceMin, custom: (value) => `(min-width: ${value}px)` },
  max: { ...deviceMax, custom: (value) => `(max-width: ${value}px)` },
};
