import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'

export function ScrollToError() {
  const formik = useFormikContext()
  const submitting = formik?.isSubmitting

  useEffect(() => {
    const el = document.querySelector('[data-error]')
    if (!el) return
    el.parentElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [submitting])
  return null
}
